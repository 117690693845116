import * as React from 'react'
import {
  Container,
  Box,
  Text,
  SimpleGrid,
  Heading,
  useColorModeValue,
  Stack,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  List,
  Link,
  ListIcon,
  ListItem,
  AccordionIcon,
} from '@chakra-ui/react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import GatsbyLink from '../components/link'
import { Frozen } from '../icons/usp'
import Pie from '../icons/pie-icon'

const IndexPage = () => {
  const headingColor = useColorModeValue(`headingColor`, `dark.headingColor`)

  return (
    <Layout>
      <Seo
        title="Frequently Asked Questions | Mud Foods"
        description="Got a question about our pies? Take a look at our frequently asked questions which can answer any queries you may have."
      />
      <Container py={5}>
        <Stack spacing={4}>
          <Heading as="h1" color={headingColor}>
            FAQ
          </Heading>
          <Text>
            If you are an existing customer, thanks so much for your continuing
            support. It means a lot to us. If you are a new customer, welcome to
            the Mud family! We hope to build a long and happy relationship with
            you too.
          </Text>
          <Heading as="h2" size={2}>
            GENERAL INFO
          </Heading>
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Do you make Gluten Free Pies?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                We've updated our range to bring you the best of our traditional
                pies. As a result, we've said a fond farewell to our gluten-free
                options for the moment.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Where can I buy Mud pies?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Follow this{' '}
                <Link variant="inline" as={GatsbyLink} to="/stockists">
                  link
                </Link>{' '}
                and enter your postcode to find your nearest fine food stockist.
                If we're not in your area, let us know who you think should
                stock Mud Foods and we'll get in touch with them.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    How many people will one pie feed?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Mud's 270g pies and 250g squiches are generous portions for one
                but many of our customers share between two people with a
                pairing of vegetables and mash or whatever you choose.
                <br />
                Our 240g fruit pies are suitable for two people, unless you’ve
                got a very healthy appetite!
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <Heading as="h2" size={2}>
            DELIVERY INFO
          </Heading>
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    When will they be delivered?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text>
                  We aim to deliver within 4 working days of receiving your
                  order however, delivery is normally quicker than this but in
                  exceptional circumstances which are ordinarily out of our
                  control, it may be longer. This is rare but if this happens
                  we'll be in touch so you're not left wondering.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Where do you deliver?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text>
                  We deliver to most mainland UK postcodes by courier in safe
                  and secure chilled packaging but unfortunately there are a
                  handful of remoter parts that the couriers cannot guarantee
                  within 48-hours and those postcodes are as follows:
                </Text>
                <SimpleGrid columns={[2, 3, 4]} spacing={2}>
                  <Text>AB31 - AB35</Text>
                  <Text>AB41 - AB5</Text>
                  <Text>AB36 - AB38</Text>
                  <Text>AB55 - AB56</Text>
                  <Text>BT (ALL)</Text>
                  <Text>D (ALL)</Text>
                  <Text>FK17 - FK21</Text>
                  <Text>G83</Text>
                  <Text>GY (ALL)</Text>
                  <Text>HS1 - HS9</Text>
                  <Text>IM (ALL)</Text>
                  <Text>IV (ALL)</Text>
                  <Text>KA27</Text>
                  <Text>KA28</Text>
                  <Text>KW0 - KW1</Text>
                  <Text>KW15 - KW17</Text>
                  <Text>PA20 - PA78</Text>
                  <Text>PH32 - PH33</Text>
                  <Text>PH15 - PH18</Text>
                  <Text>PH19 - PH29</Text>
                  <Text>PH45 - PH4</Text>
                  <Text>PH30 - PH31</Text>
                  <Text>PH34 - PH44</Text>
                  <Text>PH49 - PH99</Text>
                  <Text>ZE (ALL)</Text>
                </SimpleGrid>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Do you deliver pies every day of the week?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text>
                  We accept orders 7-days a week and deliver Tuesday through to
                  Saturday.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    What are the delivery charges?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <List>
                  <ListItem>Free shipping on orders over £45</ListItem>
                  <ListItem>£4.99 on orders under £45</ListItem>
                </List>
                (unless specified in a promotion).
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <Heading as="h2" size={2}>
            COOKING & STORAGE
          </Heading>
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    How long will they keep?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text>
                  All Mud's delicious produce is freshly made by hand in small
                  batches to order. The pies have 6 days chilled shelf life and
                  the quiches 4 days but all our produce freezes beautifully and
                  can be cooked from frozen for convenience. Full details are
                  clearly marked on each box upon arrival.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Can they be frozen?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text>
                  Yes, the pies and squiches freeze beautifully for 6 months.
                  For convenience and ease they cook brilliantly from frozen
                  too.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    How should I cook my savoury pies?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Heading as="h4" fontSize={'xl'} my={2}>
                  In the oven{' '}
                </Heading>
                <List spacing={3}>
                  <ListItem>
                    <strong>From chilled</strong>: pre heat oven to 180°C
                    (160°C)/ gas mark 4, remove all packaging and place pie on a
                    pre heated baking tray for 25-30 mins.
                  </ListItem>
                  <ListItem mb={8}>
                    <strong>From frozen</strong>: pre heat oven to 180°C
                    (160°C)/ gas mark 4, remove all packaging and place pie on a
                    pre heated baking tray for 50-55 mins.
                  </ListItem>
                </List>

                <Heading as="h4" fontSize={'xl'} my={2}>
                  With an air fryer
                </Heading>
                <Text>
                  Gently place the pie into the preheated air fryer basket. Make
                  sure it's centered and not touching the sides.
                </Text>
                <Text>
                  Adjust the cooking time depending on the size and type of pie.
                </Text>
                <List spacing={3} my={2}>
                  <ListItem>
                    <ListIcon as={Pie} boxSize={4} />
                    <strong>From chilled</strong>: 20 minutes at 180 degrees.
                  </ListItem>

                  <ListItem>
                    <ListIcon as={Frozen} boxSize={4} />
                    <strong>From frozen</strong>: 30 minutes at 160 degrees.
                  </ListItem>
                </List>
                <Text>
                  Monitor the progress to ensure even cooking, and you may need
                  to rotate the pie halfway through the cooking time.
                </Text>
                <Text>
                  All air fryers vary, so consult the manufacturers’
                  instructions. And always check the pie is piping hot before
                  eating. To confirm that your pie is fully cooked, use a meat
                  thermometer to check that the internal temperature reaches
                  160°F (71°C) for savoury pies.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    How should I cook my squiches?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text>
                  From chilled: pre heat oven to 180°C (160°C)/ gas mark 4,
                  remove all packaging and place squiche on a pre heated baking
                  tray for 20-25 mins.
                </Text>
                <Text>
                  From frozen: pre heat oven to 180°C (160°C)/ gas mark 4,
                  remove all packaging and place squiche on a pre heated baking
                  tray for 45-50 mins.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    How should I cook my fruit pies?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text>
                  From chilled: pre heat oven to 180°C (160°C)/ gas mark 4,
                  remove all packaging and place pie on a pre heated baking tray
                  for 20-25 mins.
                </Text>
                <Text>
                  From frozen: pre heat oven to 180°C (160°C)/ gas mark 4,
                  remove all packaging and place pie on a pre heated baking tray
                  for 45-50 mins.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    How should I store my pies/squiches?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text>
                  Your pies are delivered in a temperature-controlled box. They
                  will need to be placed in the fridge before the end of the day
                  they are delivered. Your pies will arrive with a 6 day chilled
                  shelf life (4 days for squiches) and can be frozen for up to
                  six months. Please note that our pies are still delicious when
                  cooked from frozen.
                </Text>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Stack>
      </Container>
    </Layout>
  )
}

export default IndexPage
